import React, { useEffect, useMemo, useState } from "react";
import Button from "../../../common/Button/Button";
import Input from "../../../common/Input/Input";
import TokenselectModal from "../../../common/TokenselectModal/TokenselectModal";
import useStakeTokenBalance from "../../../../CustomHook/useStakeTokenBalance";
import { store } from "../../../../app/store";
import { TOKEN_DATA } from "../../../../interfaces/Liquidity";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { cryptoDecimals, validateInputField } from "../../../../utils/helpers";
import { DOLLAR_VAL, INPUTS, MODAL_STATE } from "../../../../interfaces/common";
import { TradeData } from "../../../../services/ApiServices/apiService";
import { executeUnStake } from "../../../../services/stakingServices/stakingHelper";
import { daysToValueMap } from "../../../../utils/constants";
import { useWalletConnect } from "../../../../CustomHook/useWalletConnect";
import useIsWrongNetwork from "../../../../CustomHook/useisWrongNetwork";
import TxnModal from "../../../common/Modals/TxnModal/TxnModal";

export const UnstakeTab = ({
  tokenData,
  refreshData,
}: {
  tokenData: any;
  refreshData: any;
}) => {
  const dispatch = useAppDispatch();
  const { walletProvider } = useWalletConnect();
  const isWrongNetwork = useIsWrongNetwork();
  const { walletAddress }: { walletAddress: string } = useAppSelector(
    (store: any) => store?.user
  );
  const [unStakeInput, setUnStakeInput] = useState<INPUTS>({
    inputValue: "",
    convertedValue: "",
  });
  const [modalData, setModalData] = useState<MODAL_STATE>({
    status: "",
    bodyText: "",
    title: "",
    txHash: "",
  });
  const [tk1DollarValue, setTk1DollarValue] = useState<number | string>(0.0);
  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    emptyValues();
    trade();
  }, [tokenData]);

  const emptyValues = async () => {
    setUnStakeInput({
      convertedValue: "",
      inputValue: "",
    });
  };

  const trade = async () => {
    let getDollarPriceValue: DOLLAR_VAL = await TradeData(
      tokenData.tokenSymbol
    );

    if (
      !getDollarPriceValue?.token0 ||
      getDollarPriceValue?.token0 === "This token is not Listed on CMC."
    ) {
      setTk1DollarValue(0.0);
    } else {
      setTk1DollarValue(getDollarPriceValue?.token0);
    }
  };

  const handleInput = async (e: string, max: boolean) => {
    const response: boolean | string = await validateInputField(
      e,
      tokenData?.tokenDecimal,
      max,
      emptyValues
    );
    if (response) {
      let convertedValue: string = (
        max ? response : Number(response) * 10 ** tokenData?.tokenDecimal
      ).toLocaleString("fullwide", {
        useGrouping: !1,
      });

      let originalValue: string = (
        max
          ? cryptoDecimals(Number(response) / 10 ** tokenData?.tokenDecimal)
          : response
      ).toLocaleString("fullwide", {
        useGrouping: !1,
      });

      setUnStakeInput({
        convertedValue: convertedValue,
        inputValue: originalValue,
      });
    }
  };

  const unStakeAsset = async () => {
    const unstakeRes = await executeUnStake({
      stakeToken: tokenData?.tokenAddress,
      unstakeAmount: unStakeInput?.convertedValue,
      platformFee: tokenData?.platformFee,
      stakeType: tokenData.stakingtype,
      dispatch,
      walletAddress,
      walletProvider,
      setModalData,
    });
    if (unstakeRes) {
      await refreshData();
    }
  };
//design fixes
  const insufficientBalance =
    Number(tokenData?.amount?.unlockAmount) <
    Number(unStakeInput?.convertedValue);
  return (
    <>
      <section className="unstakeTab">

        <div className="activeCardtoken">
          <ul className="listToken">
            <li>
              <h5>Unstaking Amount</h5>
              <h6>
                <span>Balance :&nbsp;</span>{" "}
                {cryptoDecimals(
                  tokenData?.amount?.unlockAmount /
                    10 ** tokenData?.tokenDecimal || 0
                )}
              </h6>
            </li>
            <li>
              <div className="inputwithBalance">
                <Input
                  placeholder="0"
                  className="without_bg inputActive"
                  type="number"
                  value={unStakeInput?.inputValue}
                  onChange={(e: any) => handleInput(e.target.value, false)}
                  disabled={isWrongNetwork || !walletAddress}
                />
              </div>
              <div className="listRight">
                <Button
                  text="MAX"
                  className="maxBtn without_bg_border"
                  onClick={() =>
                    Number(tokenData?.amount?.unlockAmount) > 0
                      ? handleInput(tokenData?.amount?.unlockAmount, true)
                      : {}
                  }
                />
                <TokenselectModal field="" readOnly={true} data={tokenData} />
              </div>
            </li>
                <h5>
                  ($
                  {cryptoDecimals(
                    Number(tk1DollarValue) * Number(unStakeInput?.inputValue) ||
                      0
                  )}
                  )
                </h5>
          </ul>
        </div>
        <Button
          className={`btnSize  ${
            !walletAddress
              ? "fluid"
              : insufficientBalance || Number(unStakeInput?.inputValue) == 0
              ? "grayBorder"
              : "fluid"
          }`}
          fluid
          disabled={
            !walletAddress
              ? false
              : insufficientBalance || Number(unStakeInput?.inputValue) == 0
          }
          text={
            !walletAddress
              ? "Connect Wallet"
              : insufficientBalance
              ? `Insufficient ${tokenData?.tokenSymbol}`
              : "Unstake Asset"
          }
          onClick={() => {
            setShow(true);
            unStakeAsset();
          }}
        />
      </section>
      <TxnModal
        show={show}
        handleClose={() => setShow(false)}
        data={modalData}
      />
    </>
  );
};
