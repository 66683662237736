import "./XBridge.scss";

const XBridge = () => {
    return (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
<h1>Coming Soon..</h1>
        </div>
    )
}

export default XBridge
