import BN from "bn.js";
import { store } from "../../app/store";
import { STAKING } from "../../interfaces/staking";
import { nativeTokenAddress } from "../../utils/constants";
import { getAllowanceAndApprovalHelper } from "../LiquidityServices/AddLiquidityHelper";
import { calculateGasPrice } from "../contractServices/OkxContractServices";
import { callContractSendMethod, callGasMethod } from "../contractServices/contractMethods";
import { toFunctionBigNumber } from "../../utils/OkxHelpers";

export const findGas =async (data: STAKING)=>{
  const {
    stakeToken,
    stakeAmount,
    stakeType,
    dispatch,
    walletAddress,
    walletProvider,
    platformFee,
    spender,
    setModalData,
  } = data;
  // debugger;
  const list = store.getState()?.user?.contractDetails;
    let response: any;
    let n1 = new BN(platformFee.toString());
    let n2 = new BN(stakeAmount.toString());

    let sumAmount = Number(stakeAmount)+Number(platformFee)
    const gasPrice =  await calculateGasPrice(walletProvider);

  const res = await dispatch(
    callGasMethod(
      "deposit",
      [stakeToken, stakeAmount, stakeType],
      walletAddress,
      "staking",
      stakeToken === nativeTokenAddress
        ? (
          toFunctionBigNumber(stakeAmount-(stakeAmount*10/100))
          )?.toString()
        : platformFee,
      list?.stakingFactory?.address,
      walletProvider,
      gasPrice
    )
  );

  return res
}
export const executeStake = async (data: STAKING) => {
 
  try {
    const {
      stakeToken,
      stakeAmount,
      stakeType,
      dispatch,
      walletAddress,
      walletProvider,
      platformFee,
      spender,
      setModalData,
    } = data;
    const list = store.getState()?.user?.contractDetails;
    let response: any;
    const gasPrice =  await calculateGasPrice(walletProvider);

    if (stakeToken !== nativeTokenAddress) {
      setModalData({
        title: "Approval",
        bodyText: `Please confirm approval for staking`,
        status: "in-progress",
        txHash: null,
      });
      response = await getAllowanceAndApprovalHelper({
        customToken: stakeToken,
        dispatch,
        tokenOneAddress: 0,
        tokenTwoAddress: 0,
        walletAddress,
        inputOne: stakeAmount,
        walletProvider,
        spender,
      });
    }
    if (stakeToken == nativeTokenAddress) {
      response = true;
    }

    if (response) {
      if (stakeToken !== nativeTokenAddress) {
        setModalData({
          title: "Approval",
          bodyText: `Approval success`,
          status: "success",
          txHash: null,
        });
      }
      setModalData({
        title: "Staking",
        bodyText: `Please confirm transaction for Staking Token`,
        status: "in-progress",
        txHash: null,
      });
    
      const res = await dispatch(
        callContractSendMethod(
          "deposit",
          [stakeToken, stakeAmount?.convertedValue, stakeType],
          walletAddress,
          "staking",
          stakeToken === nativeTokenAddress
            ? (
                BigInt(platformFee) + BigInt(stakeAmount?.convertedValue)
              )?.toString()
            : platformFee,
          list?.stakingFactory?.address,
          walletProvider,gasPrice
        )
      );
      if (
        res?.code != 4001 &&
        res?.code != -32603 &&
        res?.code != 5000 &&
        res?.transactionHash
      ) {
        setModalData({
          title: "Staking",
          bodyText: `Transaction successful for Staking Token`,
          status: "success",
          txHash: res?.transactionHash,
        });
      } else if (
        res?.code == undefined ||
        res?.code == 4001 ||
        res?.code == -32603 ||
        res?.code == 5000
      ) {
        setModalData({
          title: "Staking",
          bodyText: res?.message.split("{")[0]
            ? res?.message.split("{")[0]
            : res?.message.split(":")[0],
          status: "failed",
          txHash: null,
        });
      }
      return res;
    } else {
      setModalData({
        title: "Approval",
        bodyText: `Approval Failed`,
        status: "failed",
        txHash: null,
      });
    }
  } catch (error) {
    return 0;
  }
};

export const executeUnStake = async (data: any) => {
  try {
    const {
      stakeToken,
      unstakeAmount,
      stakeType,
      dispatch,
      walletAddress,
      walletProvider,
      platformFee,
      setModalData,
    } = data;
    const list = store.getState()?.user?.contractDetails;
    setModalData({
      title: "Unstaking",
      bodyText: `Please confirm transaction for Unstaking Token`,
      status: "in-progress",
      txHash: null,
    });
    const gasPrice =  await calculateGasPrice(walletProvider);

    const res = await dispatch(
      callContractSendMethod(
        "withdraw",
        [stakeToken, unstakeAmount, stakeType],
        walletAddress,
        "staking",
        platformFee,
        list?.stakingFactory?.address,
        walletProvider,
        gasPrice
      )
    );
    if (
      res?.code != 4001 &&
      res?.code != -32603 &&
      res?.transactionHash &&
      res?.code != 5000
    ) {
      setModalData({
        title: "Unstaking",
        bodyText: `Transaction successful for Unstaking Token`,
        status: "success",
        txHash: res?.transactionHash,
      });
    } else if (
      res?.code == undefined ||
      res?.code == 4001 ||
      res?.code == -32603 ||
      res?.code == 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: res?.message.split("{")[0]
          ? res?.message.split("{")[0]
          : res?.message.split(":")[0],
        status: "failed",
        txHash: null,
      });
    }
    return res;
  } catch (error) {
    return 0;
  }
};

export const emergencyWithdrawByUser = async (data: any) => {
  try {
    const {
      stakeToken,
      stakeType,
      dispatch,
      walletAddress,
      walletProvider,
      platformFee,
      setModalData,
    } = data;
    const list = store.getState()?.user?.contractDetails;
    setModalData({
      title: "Emergency Withdraw",
      bodyText: `Please confirm transaction for Emergency Withdraw`,
      status: "in-progress",
      txHash: null,
    });
    const gasPrice =  await calculateGasPrice(walletProvider);

    const res = await dispatch(
      callContractSendMethod(
        "emergencyWithdraw",
        [stakeToken, stakeType],
        walletAddress,
        "staking",
        platformFee,
        list?.stakingFactory?.address,
        walletProvider,
        gasPrice
      )
    );
    if (
      res?.code != 4001 &&
      res?.code != -32603 &&
      res?.transactionHash &&
      res?.code != 5000
    ) {
      setModalData({
        title: "Emergency Withdraw",
        bodyText: `Transaction successful for Emergency Withdraw`,
        status: "success",
        txHash: res?.transactionHash,
      });
    } else if (
      res?.code == undefined ||
      res?.code == 4001 ||
      res?.code == -32603 ||
      res?.code == 5000 ||
      res?.code == -32000
    ) {
      setModalData({
        title: "Emergency Withdraw",
        bodyText: res?.message.split("{")[0]
          ? res?.message.split("{")[0]
          : res?.message.split(":")[0],
        status: "failed",
        txHash: null,
      });
    }
    return res;
  } catch (error) {
    // toast.error(error?.message);
    // errorHelperContract(error, "call", "getTokenApproval");
    return 0;
  }
};
export const enablePool = async (data: any) => {
  try {
    const {
      approveLpToken,
      proxyAddress,
      dispatch,
      walletAddress,
      walletProvider,
    } = data;
    
    const response: any = await getAllowanceAndApprovalHelper({
      customToken: approveLpToken,
      dispatch,
      tokenOneAddress: 0,
      tokenTwoAddress: 0,
      walletAddress,
      inputOne: { inputValue: 0 },
      walletProvider,
      spender: proxyAddress,
    });
    return response;
  
  } catch (error) {
    return 0;
  }
};

export const userDeposit = async (data: any) => {
  try {
    const {
      tokenAddress,
      proxyAddress,
      dispatch,
      walletAddress,
      walletProvider,
      fee,
      stakeType,
      inputValue,
      setModalData,
    } = data;
    const list = store.getState()?.user?.contractDetails;
    setModalData({
      title: "Farming",
      bodyText: `Please confirm transaction for Depositing LP Tokens`,
      status: "in-progress",
      txHash: null,
    });
    const gasPrice =  await calculateGasPrice(walletProvider);

    const res = await dispatch(
      callContractSendMethod(
        "deposit",
        [tokenAddress, stakeType, inputValue],
        walletAddress,
        "farm",
        fee,
        list?.farm?.address,
        walletProvider,
        gasPrice
      )
    );
    if (
      res?.code != 4001 &&
      res?.code != -32603 &&
      res?.transactionHash &&
      res?.code != 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: `Transaction successful for Depositing`,
        status: "success",
        txHash: res?.transactionHash,
      });
      return true;
    } else if (
      res?.code == undefined ||
      res?.code == 4001 ||
      res?.code == -32603 ||
      res?.code == 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: res?.message.split("{")[0]
          ? res?.message.split("{")[0]
          : res?.message.split(":")[0],
        status: "failed",
        txHash: null,
      });
      return false;
    }
  } catch (error) {
    console.log("error", error);
    return 0;
  }
};
export const emergencyWithdraw = async (data: any) => {};
export const userWithdraw = async (data: any) => {
  try {
    const {
      tokenAddress,
      proxyAddress,
      dispatch,
      walletAddress,
      walletProvider,
      fee,
      stakeType,
      inputValue,
      setModalData,
    } = data;
    const list = store.getState()?.user?.contractDetails;
    setModalData({
      title: "Farming",
      bodyText: `Please confirm transaction for Withdrawing Tokens`,
      status: "in-progress",
      txHash: null,
    });
    const gasPrice =  await calculateGasPrice(walletProvider);

    const res = await dispatch(
      callContractSendMethod(
        "withdraw",
        [tokenAddress, stakeType, inputValue],
        walletAddress,
        "farm",
        fee,
        list?.farm?.address,
        walletProvider,
        gasPrice
      )
    );
    if (
      res?.code != 4001 &&
      res?.code != -32603 &&
      res?.transactionHash &&
      res?.code != 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: `Transaction successful for Withdrawing`,
        status: "success",
        txHash: res?.transactionHash,
      });
      return true;
    } else if (
      res?.code == undefined ||
      res?.code == 4001 ||
      res?.code == -32603 ||
      res?.code == 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: res?.message.split("{")[0]
          ? res?.message.split("{")[0]
          : res?.message.split(":")[0],
        status: "failed",
        txHash: null,
      });
      return false;
    }
  } catch (error) {
    return 0;
  }
};

export const executeHarvesting = async (data: any) => {
  try {
    const {
      tokenAddress,
      proxyAddress,
      dispatch,
      walletAddress,
      walletProvider,
      fee,
      stakeType,
      setModalData,
    } = data;
    const list = store.getState()?.user?.contractDetails;
    setModalData({
      title: "Farming",
      bodyText: `Please confirm transaction for Harvesting`,
      status: "in-progress",
      txHash: null,
    });
    const gasPrice =  await calculateGasPrice(walletProvider);

    const res = await dispatch(
      callContractSendMethod(
        "harvest",
        [tokenAddress, stakeType],
        walletAddress,
        "farm",
        fee,
        list?.farm?.address,
        walletProvider,
        gasPrice
      )
    );
    if (
      res?.code != 4001 &&
      res?.code != -32603 &&
      res?.transactionHash &&
      res?.code != 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: `Transaction successful for Harvesting`,
        status: "success",
        txHash: res?.transactionHash,
      });
      return true;
    } else if (
      res?.code == undefined ||
      res?.code == 4001 ||
      res?.code == -32603 ||
      res?.code == 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: res?.message.split("{")[0]
          ? res?.message.split("{")[0]
          : res?.message.split(":")[0],
        status: "failed",
        txHash: null,
      });
      return false;
    }
  } catch (error) {
    return 0;
  }
};
